import { Colors } from '@/shared/util/colors';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const HasGuaranteeTag = () => {
  const { t } = useTranslation();
  return (
    <Typography
      borderRadius={'4px'}
      bgcolor={'#D0E8F0'}
      color={'primary'}
      variant={'clickAble'}
      fontSize={10}
      textTransform="uppercase"
      paddingY="3px"
      paddingX={1}>
      {t('Vip.ExtendedGuarantee')}
    </Typography>
  );
};
