import { useCallback, useMemo, useState } from 'react';
import { useApi } from '../util/api';
import { useAuth } from './auth-hook';
import {
  BuildInTrackEvents,
  TrackClassifiedItemEvent,
  TrackEvent,
} from '@/shared/util/gtm';
import { ClassifiedItem, UserProfileType } from '../lib-api';
import { LoadingState } from '../util/general-helpers';

export function useFavourite(item: ClassifiedItem) {
  const [loading, setLoading] = useState<LoadingState>(LoadingState.None);
  const {
    favorites: favorites,
    addToFavorites,
    removeFromFavorites,
  } = useAuth();

  const isCurrentlyLiked = useMemo(() => {
    return (
      item &&
      favorites &&
      favorites.some(favorite => favorite.classifiedId === item.id)
    );
  }, [favorites, item]);

  const { likeApi } = useApi();

  const add = useCallback(async () => {
    if (item) {
      setLoading(LoadingState.Loading);
      addToFavorites(item.id);
      await likeApi.apiLikeFavoritePost({
        favoriteRequest: {
          isFavorite: true,
          classifiedId: item.id,
        },
      });
      setLoading(LoadingState.Success);
      TrackClassifiedItemEvent(BuildInTrackEvents.OnClassifiedFavorited, item);
    }
  }, [likeApi, addToFavorites, item]);

  const remove = useCallback(async () => {
    if (item) {
      setLoading(LoadingState.Loading);
      removeFromFavorites(item.id);
      await likeApi.apiLikeFavoritePost({
        favoriteRequest: {
          isFavorite: false,
          classifiedId: item.id,
        },
      });
      setLoading(LoadingState.Success);
      TrackEvent(BuildInTrackEvents.OnClassifiedUnFavorited);
    }
  }, [likeApi, item, removeFromFavorites]);

  return {
    addLike: add,
    removeLike: remove,
    isCurrentlyLiked,
    loading,
  };
}
