import { ClassifiedItem, UserProfileType } from '@/shared/lib-api';
import { LocationCoords } from '@/shared/store/reducers/AuthSlice';
import { thousandNumberSeperator } from '../general-helpers';
import { formatDate } from './general-helper';
import { DistanceBetween2Points } from './geolocation-helper';

export const MileageDisplay = (mileage: number) => {
  if (mileage === undefined) {
    return undefined;
  }

  return thousandNumberSeperator(mileage) + ' ' + 'km';
};

export const KmLDisplay = (kml?: number) => {
  if (kml === undefined) {
    return undefined;
  }
  return `${kml.toString()} km/l`;
};

export const RangeDisplay = (range?: number) => {
  if (range === undefined) {
    return undefined;
  }
  return (range ? thousandNumberSeperator(range) : '') + ' ' + 'km rækkevidde';
};

export const RangeDisplayVip = (range?: number) => {
  if (range === undefined) {
    return undefined;
  }
  return (range ? thousandNumberSeperator(range) : '') + ' ' + 'km';
};

export const CreatedDateDisplay = (date?: Date) => {
  return (
    'Oprettet ' +
    new Date(date.toString()).toLocaleDateString('da', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
  );
};

export const KmLOrRangeDisplay = (item: ClassifiedItem) => {
  switch (item.fueltype) {
    case 'El':
      return RangeDisplay(item.distance);
    default:
      return KmLDisplay(item.kml);
  }
};

export const YellowPlatesDisplay = (item: ClassifiedItem) => {
  if (` ${item.headLine?.toLowerCase()} `?.includes(' van ')) {
    return YellowPlates();
  }
  switch (item.systemData.category) {
    case 'Varevogn ekskl moms':
    case 'Varevogn inkl moms':
      return YellowPlates();
    default:
      switch (item.fueltype) {
        case 'El':
        case 'Hybrid':
          return BatteryCapacityDisplay(item.batteryCapacity);
        default:
          return GearDisplay(item.gearType);
      }
  }
};

export const HorsePowerOrCapacityDisplay = (item: ClassifiedItem) => {
  if (
    ['Varevogn ekskl moms', 'Varevogn inkl moms'].includes(
      item.systemData.category,
    ) &&
    item.fueltype === 'El'
  ) {
    return BatteryCapacityDisplay(item.batteryCapacity);
  }
  return HorsepowerDisplay(item.horsepower);
};

export const HorsePowerOrCapacityDisplay2 = (item: ClassifiedItem) => {
  if (['El', 'Benzin/El', 'Diesel/El'].includes(item.fueltype)) {
    return BatteryCapacityDisplay(item.batteryCapacity);
  }
  return HorsepowerDisplay(item.horsepower);
};

export const TransmissionDisplay = (transmission: string) => {
  switch (transmission) {
    case 'Automatisk':
      return 'Automatgear';
    case 'Manuel':
      return 'Manuelt gear';
    default:
      return transmission;
  }
};

export const HorsepowerDisplay = (horsepower?: number) => {
  if (horsepower === undefined) {
    return undefined;
  }
  return `${thousandNumberSeperator(horsepower)} hk`;
};

export const BatteryCapacityDisplay = (batteryCapacity?: number) => {
  if (batteryCapacity === undefined) {
    return undefined;
  }
  return `${thousandNumberSeperator(batteryCapacity)} kWh`;
};

export const PropellantDisplay = (fuelType: string) => {
  if (fuelType === 'Hybrid') {
    return 'Plug-in hybrid';
  } else if (fuelType === 'Benzin/El') {
    return 'Hybrid (benzin/el)';
  } else if (fuelType === 'Diesel/El') {
    return 'Hybrid (diesel/el)';
  }

  return fuelType;
};

export const GearDisplay = (gear?: string) => {
  if (gear === undefined) {
    return undefined;
  }
  switch (gear) {
    case 'Automatisk':
      return 'Automatgear';
    case 'Manuel':
      return 'Manuelt gear';
    default:
      break;
  }
};

export const CategoryDisplay = (category: string) => {
  switch (category) {
    case 'Lastbiler':
      return 'Lastbil';
    case 'Brugte biler':
      return 'Brugt';
    case 'Leasing biler':
      return 'Leasing';
    case 'Busser':
      return 'Bus';
    case 'Autocampere':
      return 'Autocamper';
    default:
      return category;
  }
};

export const FooterLocationDisplay = (
  item: ClassifiedItem,
  userCoordinates: LocationCoords,
) => {
  let result = '';
  if (userCoordinates && item.owner.latitude && item.owner.longitude) {
    let kmDistance = DistanceBetween2Points(
      userCoordinates.lat,
      userCoordinates.long,
      item.owner.latitude,
      item.owner.longitude,
    );
    result = `${thousandNumberSeperator(kmDistance)} km`;
  }

  return result;
};

export const LocationDisplay = (
  item: ClassifiedItem,
  userCoordinates: LocationCoords,
) => {
  let result = item.owner.city;
  if (userCoordinates && item.owner.latitude && item.owner.longitude) {
    result += ` (${FooterLocationDisplay(item, userCoordinates)})`;
  }

  return result;
};

export const LeasingDurationDisplay = (leasingDuration: string) => {
  let result = leasingDuration.replace('mdr', 'måneder');
  return result;
};

export const LeasingResidualValueDisplay = (leasingResidualValue: string) => {
  return 'Restværdi ' + leasingResidualValue;
};

export const LeasingDownPaymentValueDisplay = (
  leasingDownPaymentValue: string,
) => {
  return 'Udbetaling ' + leasingDownPaymentValue;
};

export const LeasingMileagePerYearValueDisplay = (
  leasingMileagePerYearValue: string,
) => {
  if (leasingMileagePerYearValue === 'Ubegrænset km') {
    return leasingMileagePerYearValue;
  }
  return leasingMileagePerYearValue + ' inkl. pr år';
};

export const SellerDisplay = (profileType: UserProfileType) => {
  switch (profileType) {
    case UserProfileType.Company:
      return 'Forhandler';
    case UserProfileType.Private:
      return 'Privat sælger';
    default:
      break;
  }
};

export const RegDateDisplay = (item: ClassifiedItem) => {
  let monthAndYear: string = '';
  if (item.firstRegDate && item.firstRegDate > new Date()) {
    return 'Fabriksny';
  }
  if (
    new Date(item.yearOfRegistration, item.monthOfRegistration, 1) > new Date()
  ) {
    return 'Fabriksny';
  }
  if (item.yearOfRegistration) {
    if (item.monthOfRegistration) {
      monthAndYear = `${item.monthOfRegistration}/`;
    }

    monthAndYear += item.yearOfRegistration;
  } else if (item.year) {
    return item.year;
  }
  return monthAndYear;
};
export const YellowPlates = () => {
  return 'Gule plader';
};

export const IsElectricOrHybridElectric = (item: ClassifiedItem): boolean => {
  const fuelTypes = ['el', 'benzin/el', 'hybrid', 'diesel/el'];
  return fuelTypes.includes(item?.fueltype?.toLowerCase());
};
