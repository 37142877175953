import { Box, Skeleton } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import leftArrowImage from '@/shared/icons/left-arrow-image.svg';
import rightArrowImage from '@/shared/icons/right-arrow-image.svg';
import { Colors } from '@/shared/util/colors';
import placeholderImage from '@/shared/images/placeholder-image.svg';
import { useScreenSizeContext } from '@/pages/_app';
import Carousel from 'react-simply-carousel';

interface GalleryBoxProps {
  items: string[];
  'top-border'?: boolean;
  minHeight: number;
  seoSrp?: boolean;
  hideBullets?: boolean;
  alt: string;
  eager?: boolean;
}

export const GalleryBoxListCard = (props: GalleryBoxProps) => {
  const { isMobile } = useScreenSizeContext();
  return (
    <Box width={{ xs: 'unset', md: 272 }} height={{ xs: '100%', md: 191 }}>
      {props.items !== undefined ? (
        <GalleryBoxGalleryCard
          {...props}
          borderRadiusImage={isMobile ? 'all' : 'none'}
        />
      ) : (
        <Skeleton variant="rectangular" height={204} />
      )}
    </Box>
  );
};

export const GalleryBoxGalleryCard = (
  props: GalleryBoxProps & { borderRadiusImage?: 'all' | 'top' | 'none' },
) => {
  const borderRadius = props.borderRadiusImage ?? 'top';
  const { isMobile } = useScreenSizeContext();
  const galleryItems = useMemo(() => {
    const images = props.items.map(image => {
      return { original: image, type: 'image' };
    });
    if (images.length === 0) {
      images.push({
        original: placeholderImage.src,
        type: 'image',
      });
    }

    return images;
  }, [props.items]);

  const [showArrows, setShowArrows] = useState<boolean>(false);

  const onClickWrapper = useCallback(
    (
      e: React.MouseEvent<HTMLInputElement, MouseEvent>,
      onClick: React.MouseEventHandler<HTMLElement>,
    ) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    },
    [],
  );

  const _renderRightNav = useCallback(
    (onClick: React.MouseEventHandler<HTMLElement>, disabled: boolean) => {
      if (isMobile) {
        return <></>;
      }
      return (
        <input
          type="image"
          src={rightArrowImage.src}
          className="image-gallery-icon image-gallery-right-nav"
          disabled={disabled}
          onClick={e => onClickWrapper(e, onClick)}
          style={{ cursor: 'pointer', paddingRight: 0, filter: 'none' }}
        />
      );
    },
    [isMobile, onClickWrapper],
  );

  const _renderLeftNav = useCallback(
    (onClick: React.MouseEventHandler<HTMLElement>, disabled: boolean) => {
      if (isMobile) {
        return <></>;
      }
      return (
        <input
          type="image"
          src={leftArrowImage.src}
          className="image-gallery-icon image-gallery-left-nav"
          disabled={disabled}
          onClick={e => onClickWrapper(e, onClick)}
          style={{ cursor: 'pointer', paddingLeft: 0, filter: 'none' }}
        />
      );
    },
    [isMobile, onClickWrapper],
  );

  const galleryItemUrls = useMemo(
    () =>
      galleryItems.map(x => ({
        original: x.original,
        renderItem: null,
        originalHeight: isMobile ? '100%' : 'unset',
        originalWidth: isMobile ? '100%' : 'unset',
        originalClass: `image-${borderRadius}-border`,
        originalAlt: props.alt,
        loading: props.eager ? 'eager' : 'lazy',
      })),
    [galleryItems, borderRadius, isMobile, props],
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  const onBeforeSlide = useCallback(currentIndex => {
    setCurrentIndex(currentIndex);
  }, []);

  const onSlide = useCallback(currentIndex => {}, []);

  const _renderBullets = useMemo(() => {
    if (props.items.length < 2 || props.hideBullets) {
      return <></>;
    }

    const itemsToShow = Math.min(props.items.length, 5);
    const currentSimulatedCarouselIndex =
      currentIndex - Math.floor((itemsToShow - 1) / 2);

    return (
      <>
        <Box
          bottom={'0px'}
          right={'0px'}
          position={'absolute'}
          width="100%"
          zIndex={100}>
          <Box
            marginBottom={1}
            display="flex"
            justifyContent={'center'}
            alignItems="center">
            {props.items && props.items.length !== 0 && (
              <Carousel
                forwardBtnProps={{ show: false }}
                backwardBtnProps={{ show: false }}
                infinite={false}
                itemsToShow={itemsToShow}
                speed={200}
                disableSwipeByMouse
                disableSwipeByTouch
                activeSlideIndex={currentSimulatedCarouselIndex}
                onRequestChange={(index, state) => {}}>
                {props.items?.map((item, index) => {
                  return (
                    <Box
                      display="flex"
                      justifyContent={'center'}
                      alignItems="center"
                      key={item}
                      padding={props.seoSrp ? 0.6 : 0.3}>
                      <Box
                        marginBottom={1.5}
                        padding={
                          Math.abs(index - currentIndex) < 2
                            ? props.seoSrp
                              ? '4.5px'
                              : '3px'
                            : props.seoSrp
                            ? '3px'
                            : '2px'
                        }
                        bgcolor={
                          index === currentIndex
                            ? Colors.White
                            : 'rgba(255, 255, 255, 0.616)'
                        }
                        borderRadius={'50%'}
                      />
                    </Box>
                  );
                })}
              </Carousel>
            )}
          </Box>
        </Box>
      </>
    );
  }, [props.items, props.hideBullets, props.seoSrp, currentIndex]);

  return (
    <Box
      display="flex"
      height="100%"
      borderRadius={'10px'}
      minHeight={props.minHeight}
      onMouseOver={() => setShowArrows(true)}
      onMouseLeave={() => setShowArrows(false)}>
      <ImageGallery
        //@ts-ignore
        items={galleryItemUrls}
        onSlide={onSlide}
        onBeforeSlide={onBeforeSlide}
        showThumbnails={false}
        showPlayButton={false}
        stopPropagation={false}
        infinite={true}
        renderCustomControls={() => _renderBullets}
        disableKeyDown={true}
        renderRightNav={_renderRightNav}
        renderLeftNav={_renderLeftNav}
        showFullscreenButton={false}
        showNav={showArrows}
        lazyLoad={true}
      />
    </Box>
  );
};
