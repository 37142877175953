import { Colors } from '@/shared/util/colors';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface DefaultTagProps {
  text: string;
}

export const DefaultTag = (props: DefaultTagProps) => {
  return (
    <Typography
      borderRadius={'4px'}
      bgcolor={'#E1EAED'}
      variant={'clickAble'}
      color={'primary'}
      fontSize={10}
      textTransform="uppercase"
      paddingY="3px"
      paddingX={1}>
      {props.text}
    </Typography>
  );
};

export const SeoTag = (props: DefaultTagProps) => {
  return (
    <Typography
      borderRadius={'4px'}
      bgcolor={'#E2F3F4'}
      variant={'clickAble'}
      color={Colors.DarkerBlue}
      fontSize={12}
      paddingY="3px"
      paddingX={1}>
      {props.text}
    </Typography>
  );
};
