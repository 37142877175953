import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const EngrosTag = () => {
  const { t } = useTranslation();
  return (
    <Typography
      borderRadius={'4px'}
      bgcolor={'#e29e9e'}
      color={'primary'}
      variant={'clickAble'}
      fontSize={10}
      textTransform="uppercase"
      paddingY="3px"
      paddingX={1}>
      {t('Vip.Engros')}
    </Typography>
  );
};
