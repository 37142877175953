import { getClassifiedVipUrl } from '@/web/util/helpers/url-helpers';
import { useCallback, useMemo } from 'react';
import { ClassifiedItem, SalesType } from '../lib-api';
import { useAppSelector } from '../store/hooks';
import { useAuth } from './auth-hook';
import { useFavourite } from './favourite-hook';
import { useLoginOrCreatedEvent } from './login-hook';

export enum BasicCarCardType {
  'FavouritesList' = 0,
  'SRPList' = 1,
  'SRPGallery' = 2,
  'AlternativesModal' = 3,
}

interface BasicCarProps {
  item: ClassifiedItem;
}

export function useBasicCarNoAuth(props: BasicCarProps) {
  const carRoute = useMemo(() => {
    if (props?.item?.url?.includes('api.itemwise')) {
      return props.item.url ?? '';
    }
    const routeToPush =
      props.item && getClassifiedVipUrl(props.item.id, props.item.headLine);
    return routeToPush ?? '';
  }, [props.item]);

  const isNewVehicle = useCallback((dateString: string) => {
    if (!dateString) {
      return false;
    }

    try {
      const date = new Date(dateString);
      let dateSevenDaysAgo = new Date();
      dateSevenDaysAgo.setDate(new Date().getDate() - 7);
      return dateSevenDaysAgo < date;
    } catch {
      return false;
    }
  }, []);

  const isLoweredInPrice = useCallback(() => {
    // Do not show reduced prices for "Ring for pris"
    if (props.item.priceFormatted === 'Ring for pris') {
      return false;
    }

    return (
      props.item.changeInMaxPrice !== undefined &&
      props.item.changeInMaxPrice <= -1000 &&
      props.item.owner.id !== 68957 &&
      props.item.systemData.price > props.item.changeInMaxPrice * -1
    );
  }, [props.item]);

  const hasGuarantee = useCallback(() => {
    return props.item.hasGuarantee;
  }, [props.item]);

  const isEngros = useCallback(() => {
    return props.item.priceLabel == 'Engros/CVR';
  }, [props.item]);

  return {
    isNewVehicle,
    isEngros,
    carRoute,
    isLoweredInPrice,
    hasGuarantee,
  };
}

export function useBasicCar(props: BasicCarProps) {
  const userCoordinates = useAppSelector(
    state => state.auth.locationCoordsFromBrowser,
  );
  const { isLoweredInPrice, isNewVehicle, carRoute, hasGuarantee, isEngros } =
    useBasicCarNoAuth(props);
  const { isLoggedIn } = useAuth();
  const { addLike, removeLike, isCurrentlyLiked, loading } = useFavourite(
    props.item,
  );

  const addToFavorites = useCallback(() => {
    addLike();
  }, [addLike]);

  const { loginOrCreate } = useLoginOrCreatedEvent(addToFavorites);

  const loginAndLike = () => {
    loginOrCreate();
  };

  return {
    loginAndLike,
    isNewVehicle,
    carRoute,
    isCurrentlyLiked,
    isLoweredInPrice,
    addLike,
    removeLike,
    isLoggedIn,
    userCoordinates,
    hasGuarantee,
    isEngros,
    loading,
  };
}
