import { ClassifiedItem } from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const LoweredPriceTag = () => {
  const { t } = useTranslation();
  return (
    <Typography
      borderRadius={'4px'}
      bgcolor={'#E1EAED'}
      color={'primary'}
      variant={'clickAble'}
      fontSize={10}
      textTransform="uppercase"
      paddingY="3px"
      paddingX={1}>
      {t('SRP.LoweredPrice')}
    </Typography>
  );
};

export const LoweredPrice: React.FC<{
  item: ClassifiedItem;
  carrousel?: boolean;
}> = props => {
  return (
    <Box
      borderRadius={'4px'}
      paddingY="3px"
      paddingX={'5px'}
      display={'flex'}
      bgcolor={Colors.White}
      boxShadow={'0px 0px 22px rgba(0, 0, 0, 0.1)'}
      alignItems={'center'}>
      <Box bgcolor={'#E71116'} padding={'1px 4px'} borderRadius={'3px'}>
        <Typography
          lineHeight={'27px'}
          fontWeight={'700'}
          color={Colors.White}
          fontSize={16}>
          {`Spar ${thousandNumberSeperator(
            props.item.changeInMaxPrice * -1,
          )} kr`}
        </Typography>
      </Box>
      {!props.carrousel && (
        <Box padding={'1px 6px'} borderRadius={'3px'}>
          <Typography
            style={{ textDecoration: 'line-through' }}
            color={'#8C8C8C'}
            fontSize={15}>
            {`${thousandNumberSeperator(props.item.previousMaxPrice)} kr`}
          </Typography>
        </Box>
      )}
      <Box
        sx={{ xs: { display: 'none' }, md: { display: 'block' } }}
        padding={'1px 4px'}
        borderRadius={'3px'}>
        <Typography fontWeight={'700'} color={'white'} fontSize={20}>
          {`${thousandNumberSeperator(
            props.item.previousMaxPrice - props.item.changeInMaxPrice * -1,
          )} kr`}
        </Typography>
      </Box>
    </Box>
  );
};
